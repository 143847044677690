<template>
  <div class="view-wrapper d-flex">
    <div class="view flex-shrink-2">

      <v-toolbar class="my-bar toolbar">
        <v-col class="d-flex" cols="12" sm="6">
        <v-text-field
          v-model="searchParams.textSearchValue"
          hide-details
          append-icon="search"
          single-line
          dense
          label="Поиск"
          filled
          rounded
          clearable

          @keydown.enter="search"
          @click:append="search"

        ></v-text-field>
        <v-autocomplete
          v-model="searchParams.companyType"
          label="Тип компании"
          auto-select-first
          class="underwriter"
          style="margin-left: 2rem"
          dense
          filled
          rounded
          :items="companyTypes"
          item-text="value"
          item-value="key"
          clearable
          @input="search"

        >

        </v-autocomplete>
          <v-text-field
            v-model="searchParams.contractNumber"
            style="margin-left: 2rem"
            label="Номер контракта"
            single-line
            clearable
            append-icon="search"
            filled
            rounded
            dense
            @keydown.enter="search"
            @click:append="search"
          >

          </v-text-field>
        </v-col>

        <v-spacer></v-spacer>
        <v-btn v-if="rightFilter('w')" icon @click="add">
          <v-icon>add</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :single-select="true"
        item-key="id"
        show-select
        hide-default-footer
        :loading="loading"
        loading-text="Загрузка... Пожалуйста подождите"
        fixed-header
        @click:row="rowClick"
      >
        <template v-slot:item.deleted="{ item }">
          <v-icon v-if="item.deleted">block</v-icon>
        </template>
      </v-data-table>
    </div>

    <div v-if="item" class="view flex-grow-2">
      <v-tabs class="toolbar">
        <v-tab>Компания</v-tab>
        <v-tab v-if="item.id">Контракты</v-tab>
        <v-toolbar class="my-bar">
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-tab-item>
          <v-toolbar class="my-bar">
            <SaveButton
              :handler="save"
              :additional-rules="!formIsValid"
            >

            </SaveButton>
            <v-btn v-if="rightFilter('d') && rightFilter('w')" icon :disabled="!item.id" @click="rem">
              <v-icon v-if="!item.deleted">block</v-icon>
              <v-icon v-else>check</v-icon>
            </v-btn>
          </v-toolbar>
          <div class="my-content">
          <Company :item="item" :vm="this" @formValidation="validateForm"></Company>
          </div>
        </v-tab-item>
        <v-tab-item>

            <ContractsList :organizationId="item.id"></ContractsList>

        </v-tab-item>

      </v-tabs>

  </div>
  </div>
</template>

<script>
import fetch from "../utils/fetch";
import dictFilter from "@/filters/dict.filter";
import Company from "../components/Company";
import vm2js from "../utils/vm2js";
import js2vm from "../utils/js2vm";
import rightFilter from "../filters/right.filter";
import ctx from "../ctx";
import SaveButton from "@/components/ButtonFacade";
import ContractsList from "@/components/companies/ContractsList";

let Item = function(d) {
  let self = this;

  d.typeLabel = d.typeKey;
  d.cityLabel = d.cityKey;

  self.typeLabel2vm = function(val) {
    return dictFilter("INS_COMPANY_TYPE", val);
  };
  self.cityLabel2vm = function(val) {
    return dictFilter("INS_CITY", val);
  };

  js2vm(self, d);
};

export default {
  data: () => ({
    ctx,
    rightFilter,
    currentPage: 0,
    totalPages: 0,
    loading: false,
    selected: [],
    headers: [
      {
        text: "Наименование",
        align: "start",
        sortable: false,
        value: "fullName"
      },
      { text: "Тип", value: "typeLabel" },
      { text: "БИН", sortable: false, value: "bin" },
      { text: "Город", value: "cityLabel" },
      { text: "Тел 1", sortable: false, value: "phone1" },
      { text: "Тел 2", sortable: false, value: "phone2" },
      { text: "Email", sortable: false, value: "email" },
      { text: "", align: "center", value: "deleted" }
    ],
    items: [],
    item: undefined,
    searchParams: {
      textSearchValue: null,
      companyType: null,
      contractNumber: null
    },
    formIsValid: false
  }),
  watch: {
    selected: function(val) {
      if (val.length === 0) this.item = undefined;
      else this.item = val[0];
    }
  },
  methods: {


    async search() {
      this.items = [];
      this.selected = [];
      this.currentPage = 0;
      const params = {
        page: this.currentPage,
        predicate: this.companyName,
        type: this.companyType,

      };
      this.loading = true;
      await this.fetchItems(params);
      this.loading = false;
    },
    add() {
      this.close();
      this.selected.push(new Item({}));
    },
    async rem() {
      if (!this.item.deleted) {
        const res = await fetch.post("/api/org/delete?id=" + this.item.id);
        if (res) this.item.deleted = true;
      } else {
        const res = await fetch.post("/api/org/restore?id=" + this.item.id);
        if (res) this.item.deleted = false;
      }
    },
    async save() {

     await this.detail.checkBin();
      if(!this.detail.isBinUnique) {
        this.validateForm(false);
        return;
      }

      const res = await fetch.post("/api/org/save", vm2js(this.item));
      if (res) {
        if (this.item.id) {
          Object.assign(
            this.items.find(i => {
              return i.id === this.item.id;
            }),
            this.item
          );
        } else {
          this.item.id = res;
          this.items.unshift(new Item(vm2js(this.item)));
        }
        ctx.src.org.push(res);
        this.close();
      }
    },
    close() {
      this.selected = [];
    },
    handleScroll(e) {
      if (
        e.target.scrollTop >=
        (e.target.scrollHeight - e.target.offsetHeight) * 0.9
      ) {
        if (this.totalPages - 1 > this.currentPage) {
          this.currentPage++;
          const params = {
            page: this.currentPage
          };
          this.fetchItems(params);
        }
      }
    },
    async fetchItems(params) {
      this.loading = true;
      let res;
     /* if (!params.predicate)
        res = await fetch.get("/api/org/get-list?page=" + params.page);
      else
        res = await fetch.get(
          "/api/org/search?predicate=" +
            params.predicate +
            "&page=" +
            params.page
        );*/
      if(Object.values(this.searchParams).some(param => param)) {
        res = await fetch.post('/api/org/get-organizations-by-criteria?page=' + this.currentPage, {
          textSearchValue: this.searchParams.textSearchValue,
          typeKey: this.searchParams.companyType,
          contractNumber: this.searchParams.contractNumber
        })
      }
      else {
        res = await fetch.get("/api/org/get-list?page=" + params.page);
      }

      if (res.first) {
        this.totalPages = res.totalPages;
        this.items = [];
      }
      res.content.forEach(r => {
        this.items.push(new Item(r));
      });
      this.loading = false;
    },
    rowClick(obj, row) {
      if (!row.isSelected) row.select(obj);
      else this.selected = [];
    },
    validateForm(isValid) {
      this.formIsValid = isValid;
    }


  },
  mounted() {
    document
      .getElementsByClassName("v-data-table__wrapper")[0]
      .addEventListener("scroll", this.handleScroll);
    this.fetchItems({ page: 0 });
  },
  computed: {
    companyTypes() {
      return ctx.src.dict['INS_COMPANY_TYPE'].items.filter(dictionaryItem => dictionaryItem.id !== 10121692);
    }
  },
  components: {
    ContractsList,
    SaveButton,
    Company
  }
};
</script>


