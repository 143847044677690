<template>
  <v-dialog v-model="show" max-width="500px">
      <v-card>
        <v-card-title class="headline">
          {{promptText}}
        </v-card-title>
        <v-card-actions>
          <v-spacer/>
          <v-btn text @click="cancel">
            Отмена
          </v-btn>
          <v-btn color="primary"  text  @click="agree">
            ОК
          </v-btn>

        </v-card-actions>
      </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      defaultValue: false
    },
    promptText: {
      type: String,
      defaultValue: "Вы уверены, что хотите удалить запись?"
    },
  },
  name: "DeleteConfirmation.vue",
  methods: {
    agree() {
      this.$emit("deletionAgreed");
    },
    cancel() {
      this.$emit("deletionCanceled")
    }
  }
}
</script>

<style scoped>

</style>
