export const  allowedToEditKiasContracts = [
    "10223156",
"11800884",
"33482",
"20002986"

]


export const canEdit = (isNewEntry, isKiasContract) => {
    return isNewEntry || isKiasContract || allowedToEditKiasContracts.some(id => localStorage.getItem("id") === id);
}