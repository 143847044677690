<template>
  <div id="listContainer" class="my-content" style="height: 100%" >
    <v-dialog></v-dialog>
    <div v-if="!contractItem">
    <v-toolbar class="my-bar">
      <v-btn icon @click="addContract">
        <v-icon>add</v-icon>
      </v-btn>
      <v-btn icon @click="promptRemove">
        <v-icon>remove</v-icon>
      </v-btn>
    </v-toolbar>
    <div  style="min-height: 100%">

      <v-data-table

        v-model="selected"
        v-scroll.self="handleScroll"

        :headers="headers"
        :items="items"
        :items-per-page="-1"
        :single-select="true"
        item-key="id"
        show-select
        hide-default-footer
        :loading="loading"
        loading-text="Загрузка... Пожалуйста подождите"
        fixed-header
        :hidden="contractItem"
        :item-class="conditionalClasses.itemColor"

        class="overflow-y-auto"
        @click:row="rowClick"
      >
        <!--      <template #item.index ="{item}">-->
        <!--        {{ items.indexOf(item) + 1 }}-->
        <!--      </template>-->
        <template v-slot:item.startDate="{ item }">
          <span>{{ item.startDate | dateLocalFilter }}</span>
        </template>
        <template v-slot:item.endDate="{ item }">
          <span>{{ item.endDate | dateLocalFilter }}</span>
        </template>
      </v-data-table>

    </div>
      </div>
      <ContractCompanyView v-if="contractItem" :item="contractItem"
                           :options="{organizationId: organizationId}"
                           @closeContractForm="hideContractView"
                           @contractSaved="refresh"
      ></ContractCompanyView>
      <DeleteConfirmation :show="deleteDialogShown" prompt-text="Вы уверены, что хотите удалить контракт?" @deletionAgreed="remove" @deletionCanceled="hideDeletionDialog" />
  </div>
</template>

<script>
import fetch from "@/utils/fetch";
import ctx from '@/ctx'
import dateIsoFilter from "@/filters/dateIso.filter";
import ContractCompanyView from "@/components/companies/ContractCompanyView";
import {itemColor} from "@/utils/conditionalCssClasses/contracts";
import DeleteConfirmation from '../common/DeleteConfirmation';


export default {
  components: {DeleteConfirmation, ContractCompanyView},
  props: {
    organizationId: Number,
    item: Object,
  },
  data: () => ({
    ctx,
    currentPage: 0,
    totalPages: 0,
    loading: false,
    selected: [],
    contractViewShown: false,
    contractItem: undefined,
    deleteDialogShown: false,
    // computed: {
    //   itemsWithSno() {
    //     debugger;
    //     return this.headers.map((d, index) => ({ ...d, rowNum: index + 1 }))
    //   }
    // },
    headers: [
      {
        text: "№",
        align: "start",
        sortable: false,
        value: "index"
      },
      {
        text: "Номер",
        align: "start",
        value: "number"
      },
      {text: "Дата с", value: "startDate"},
      {text: "Дата по", value: "endDate"},

      {text: "Сумма", sortable: false, value: "amount"}
    ],
    items: [],
    conditionalClasses: {
      itemColor: itemColor
    }

  }),
  watch: {
    organizationId: async function (val) {
      this.init();

      this.hideContractView();
      await this.fetchItems(val);
    },

  },
  methods: {

    showContractView(selectedValue) {
      if (!selectedValue) return;

      this.contractItem = selectedValue;
      this.contractItem.organization = ctx.src.org.get(this.organizationId);

    },

    promptRemove() {
      if(this.selected.length === 0) return;
      this.deleteDialogShown = true;
    },


    hideContractView() {
      this.contractItem = undefined;

    },

    async remove() {
      if(this.selected.length === 0) return;
      this.loading = true;
      const item = this.selected[0];
      await fetch.post('/api/insurance/contracts/delete?id=' + item.id);
      this.loading = false;
      this.hideDeletionDialog();
      this.init();
      await this.fetchItems();
    },


    handleScroll(e) {

      if (
        e.target.scrollTop >=
        (e.target.scrollHeight - e.target.offsetHeight) * 0.9
      ) {
        if (this.totalPages - 1 > this.currentPage) {
          this.currentPage++;

          this.fetchItems();
        }
      }
    },
    rowClick(obj, row) {
      if (!row.isSelected) {
        row.select(obj);
        this.showContractView(obj);
      } else this.selected = [];
    },
    async fetchItems(id = this.organizationId) {
      this.loading = true;
      const result = await fetch.get(`/api/insurance/contracts/get-contract-by-organization?organizationId=${id}&page=${this.currentPage}`);
      if (result) {
        let index = this.items.length > 0 ? this.items.length + 1 : 1;
        this.totalPages = result.totalPages;
        result['content'].forEach(r => {
          r.index = index++;
          r.startDate = dateIsoFilter(r.startDate);
          r.endDate = dateIsoFilter(r.endDate);
          this.items.push(r);
        })
      }


      this.loading = false;
    },
    init() {
      this.currentPage = 0;
      this.totalPages = 0;
      this.selected = [];
      this.items = [];
    },

    hideDeletionDialog() {
      this.deleteDialogShown = false;
    },

    addContract() {
      this.contractItem = {};
      this.contractItem.organization = ctx.src.org.get(this.organizationId);
    },
    async refresh() {
      this.init();
      this.loading = true;
      await this.fetchItems();
      await this.ctx.src.contracts.init();
      this.loading = false;
    }

  },
  async mounted() {
    this.loading = true;
    this.init();
    await this.fetchItems(this.organizationId);
    document.getElementById('listContainer').addEventListener('scroll', this.handleScroll)
    this.loading = false;
  },


};
</script>
