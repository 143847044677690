<template>
  <div class="my-content" style="position: relative; height: 100%">
    <v-form ref="form">
      <v-container fluid>
        <v-row align="center">
          <v-col class="d-flex" cols="12" sm="6" style="align-items: center;">
            <v-autocomplete
              v-model="selected"
              :items="items"
              :label="'Программа'"
              item-text="name"
              item-value="id"
              required
            >
              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.name"
                    ></v-list-item-title>
                    <v-list-item-subtitle>
                      <v-row dense>
                        <v-col cols="12" sm="6">
                          <h6>Страховая сумма</h6>
                          <h4>{{ data.item.amount }}</h4>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <h6>Страховая премия</h6>
                          <h4>{{ data.item.premium }}</h4>
                        </v-col>
                      </v-row>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
            <v-btn v-if="rightFilter('w')" icon @click="add">
              <v-icon>add</v-icon>
            </v-btn>
            <v-btn
              v-if="rightFilter('w')"
              icon
              :disabled="!selected || !canEdit"
              @click="edit"
            >
              <v-icon>edit</v-icon>
            </v-btn>
            <v-btn
              v-if="rightFilter('d')"
              icon
              :disabled="!selected || !canEdit"
              @click="dialogDelete = true"
            >
              <v-icon>remove</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <ProgramHistory :item="selected"></ProgramHistory>
          </v-col>
          <Unions :vm="this"></Unions>
        </v-row>
      </v-container>
      <v-dialog v-model="dialog" max-width="500px" scrollable>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container v-if="item">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="item.name"
                    label="Наименование"
                    :readonly="!canEdit"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="item.amount"
                    label="Страховая сумма"
                    type="number"
                    :readonly="!canEdit"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="item.premium"
                    label="Страховая премия"
                    type="number"
                    :readonly="!canEdit"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="close">
              Отмена
            </v-btn>
            <SaveButton
            color="primary"
            :handler="save"
            content="OK"
            >

            </SaveButton>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline"
            >Вы точно хотите удалить программу?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="closeDelete">Отмена</v-btn>
            <v-btn color="primary" text @click="deleteConfirm">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <Detail :vm="this"></Detail>
  </div>
</template>

<script>
import fetch from "../../utils/fetch";
import js2vm from "../../utils/js2vm";
import vm2js from "../../utils/vm2js";
import Detail from "./Detail";
import Unions from "./Unions";
import rightFilter from "../../filters/right.filter";
import ProgramHistory from "./ProgramHistory.vue";
import SaveButton from "@/components/ButtonFacade";

let Item = function(d) {
  let self = this;

  js2vm(self, d);
};

export default {
  props: {
    vm: Object
  },
  data: () => ({
    rightFilter,
    items: [],
    selected: undefined,
    item: undefined,
    dialog: false,
    dialogDelete: false
  }),
  watch: {
    "vm.item": function(val) {
      this.selected = undefined;
      this.items = [];
      if (val) return this.fetchItems(val.id);
    }
  },
  methods: {
    async fetchItems(id, itemIdToSelect = undefined) {
      this.items = [];
      let res = await fetch.get(
        "/api/insurance/programs/get-by-contract?contractId=" + id
      );
      if (res)
        res.forEach(i => {
          this.items.push(new Item(i));
        });
      if(itemIdToSelect) this.selected = itemIdToSelect;
      else if (this.items.length > 0) this.selected = this.items[0].id;
    },

    add() {
      this.item = new Item({});
      this.dialog = true;
    },

    edit() {
      let d = this.items.find(i => {
        return i.id === this.selected;
      });
      this.item = new Item(vm2js(d));
      this.dialog = true;
    },

    async deleteConfirm() {
      const res = await fetch.post(
        "/api/insurance/programs/delete?id=" + this.selected
      );
      if (res) {
        let found = this.items.find(i => {
          return i.id === this.selected;
        });

        this.items.splice(found, 1);
        this.selected = undefined;
        if (this.items.length > 0) this.selected = this.items[0].id;
        this.closeDelete();
      }
    },

    close() {
      this.dialog = false;
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    reloadDetail() {
      this.detail.fetchItems(this.selected);
    },

    async save() {
      this.item.contractId = this.vm.item.id;
      const res = await fetch.post(
        "/api/insurance/programs/save",
        vm2js(this.item)
      );
      if (res) {
        await this.fetchItems(this.vm.item.id, res)
        this.close();
      }
    }
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Новая запись" : "Редактирование записи";
    },
    canEdit() {
      return this.$store.getters["isContractEditable"];
    }
  },
  mounted() {
    this.fetchItems(this.vm.item.id);
  },
  components: {
    SaveButton,
    Detail,
    Unions,
    ProgramHistory
  }
};
</script>
