<template>
  <tr>
    <td>{{ index + 1 }}</td>
    <td>{{ item.state }}</td>
    <td>{{ item.date }}</td>
    <td>{{ item.userName }}</td>
    <td>{{ item.additional.number }}</td>
    <td>{{ item.additional.amount }}</td>
  </tr>
</template>

<script>
import ctx from "@/ctx";

export default {
  props: {
    item: Object,
    index: Number
  },
  data: () => ({
    ctx
  }),
  mounted() {



  }
};
</script>

<style lang="scss" scoped>
td {
  padding: 0 2px !important;
}
</style>
