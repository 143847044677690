<template>
  <v-form ref="form" @input ="validate">
    <v-container fluid>
      <v-row aling="center">
        <v-col class="d-flex" cols="12" sm="4">
          <v-text-field
            v-model="isVerifiedLabel"
            label="Данные прошли проверку в ЕСБД"
            :readonly="true"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col class="d-flex" cols="12" sm="6">
          <v-text-field
            v-model="item.fullName"
            label="Наименование"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-text-field
            v-model="item.shortName"
            label="Краткое наименование"
            :readonly="!canEdit"
          ></v-text-field>
        </v-col>

        <v-col class="d-flex" cols="12" sm="6">
          <v-text-field
            v-model="item.bin"
            label="БИН"
            :rules="[validationRules.requiredRule, uniqueRule]"
            :error-messages="errors"
            :readonly="!canEdit"
            @input="triggerCheckBin"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-autocomplete
            v-model="item.typeKey"
            :items="ctx.src.dict['INS_COMPANY_TYPE'].items"
            :label="'Категория'"
            item-text="value"
            item-value="key"
            :readonly="isClientCompany"
            required
          ></v-autocomplete>
        </v-col>

        <v-col class="d-flex" cols="12" sm="6">
          <v-autocomplete
            v-model="item.cityKey"
            :items="ctx.src.dict['INS_CITY'].items"
            :label="'Город'"
            item-text="value"
            item-value="key"
            required
          ></v-autocomplete>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-text-field
            v-model="item.email"
            prepend-icon="alternate_email"
            :readonly="isClientCompany"
            label="Email"
          ></v-text-field>
        </v-col>

        <v-col class="d-flex" cols="12" sm="6">
          <v-text-field
            v-model="item.phone1"
            prepend-icon="phone"
            :readonly="isClientCompany"
            label="Тел 1"
          ></v-text-field>
        </v-col>
        <v-col class="d-flex" cols="12" sm="6">
          <v-text-field
            v-model="item.phone2"
            prepend-icon="phone"
            :readonly="isClientCompany"
            label="Тел 2"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ctx from "@/ctx";
import {required} from "@/utils/rules/common";
import fetch from "@/utils/fetch";
import _ from "lodash";
import {clientCompanyKey} from "@/utils/staticData/companyTypeKeys";
import {allowedToEditKiasContracts} from "@/utils/staticData/userPermissions"

export default {
  props: {
    item: Object,
    vm: Object
  },
  data: () => ({
    ctx,
    validationRules: {
      requiredRule: required,
    },
    errors: [],
    isBinUnique: true
  }),
  watch: {

    /*"this.item.bin" : async function (val) {
      alert('teswt');

    }*/


  },
  methods: {
    validate() {
      if(!this.$refs.form) return;

      this.$emit('formValidation', this.$refs.form.validate());
    },
    uniqueRule() {
      return this.isBinUnique;
    },
    async checkBin() {
        if(!this.item.bin) return;
        const organizationid = this.item.id;
        const result =  await fetch.get('/api/org/exists-by-bin?bin=' + this.item.bin + (organizationid ? `organizationId=${organizationid}` : ''));
        this.isBinUnique = !result;
      this.errors = (this.isBinUnique) ? [] :  ['Поле должно быть уникальным'];
      this.validate();

    },
    triggerCheckBin() {
      this.debouncedCheckBin();
    },


  },

  computed: {
    isNewEntry() {
      return !this.item.id;
    },
    isClientCompany() {
      return (!this.isNewEntry && this.item.typeKey === clientCompanyKey)
    },
    isVerifiedLabel() {
      return this.item.verified ? "Да" : "Нет"
    },
    canEdit() {
      return this.$store.getters['isOrganizationEditable'] || !this.item.verified;
    }
  },

  created() {
    this.debouncedCheckBin = _.debounce( function () {this.checkBin()}, 3000);
  },
  mounted() {
    this.vm.detail = this;
    this.$refs.form.validate();
    this.$store.commit('calculateIfOrganizationIsEditable', {
      isNewEntry: !this.item.id,
      isKiasOrganization: !!this.item.isn,
      userCanEditKiasContracts: allowedToEditKiasContracts.some(id => id === localStorage.getItem('id'))
    })


  }
};
</script>
