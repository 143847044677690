<template>
  <div class="view">
    <v-tabs class="my-tabs toolbar">

      <v-tab>Контракт</v-tab>
      <v-tab>Программы</v-tab>
      <v-tab>Застрахованные</v-tab>
      <v-toolbar class="my-bar">
        <v-spacer>

        </v-spacer>
        <v-textarea
          v-model="item.note"
          class="mt-9 contract-note"
          label="Примечание"
          :readonly="!currentUserCreatedContractNote"
          no-resize
          height="25"
          outlined


        >
        </v-textarea>

        <v-btn icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-tab-item>
        <v-toolbar class="my-bar">
          <v-spacer></v-spacer>
          <ContractHistory :item="item"></ContractHistory>
          <SaveButton
            :handler="save"
          ></SaveButton>

        </v-toolbar>
        <div class="my-content">

          <v-form ref="form" v-model="valid">
            <v-container fluid>
              <v-row align="center">
                <v-col class="d-flex" cols="12" sm="6">
                  <v-text-field v-model="item.number" label="Номер" :readonly="cantEdit"></v-text-field>
                </v-col>
                <!--          <v-col class="d-flex" cols="12" sm="6">-->
                <!--            <v-text-field-->
                <!--              v-model="vm.item.contractDate"-->
                <!--              label="Дата договора"-->
                <!--              type="date"-->
                <!--            ></v-text-field>-->
                <!--          </v-col>-->

                <v-col class="d-flex" cols="12" sm="6">
                  <v-autocomplete
                    v-model="item.underwriterId"
                    :items="ctx.src.org.activeByType('1')"
                    :label="'Страховая компания'"
                    item-text="fullName"
                    item-value="id"
                    :readonly="cantEdit"
                    required
                  ></v-autocomplete>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-autocomplete
                    v-model="item.organizationId"
                    :items="ctx.src.org.activeByType('2')"
                    :label="'Компания'"
                    item-text="fullName"
                    :readonly="cantEdit"
                    item-value="id"
                    required
                  ></v-autocomplete>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6">
                  <v-text-field
                    v-model="item.startDate"
                    :rules="requiredRules"
                    required
                    label="Дата с"
                    :readonly="cantEdit"
                    type="date"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-text-field
                    v-model="item.endDate"
                    :rules="requiredRules"
                    required
                    label="Дата по"
                    :readonly="cantEdit"
                    type="date"
                  ></v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6">
                  <v-text-field
                    v-model="item.amount"
                    label="Сумма"
                    :readonly="cantEdit"
                    type="number"
                  ></v-text-field>
                </v-col>
                <!--          <v-col class="d-flex" cols="12" sm="6">-->
                <!--            <v-autocomplete-->
                <!--              v-model="vm.item.productId"-->
                <!--              :items="ctx.src.products.items"-->
                <!--              :label="'Продукт'"-->
                <!--              item-text="name"-->
                <!--              item-value="id"-->
                <!--              required-->
                <!--            ></v-autocomplete>-->
                <!--          </v-col>-->
                <v-col class="d-flex" cols="12" sm="6">
                  <v-text-field
                    v-model="item.organization.cityLabel"
                    readonly
                    label="Город"
                  ></v-text-field>
                </v-col>


                <v-col class="d-flex" cols="12" sm="6">
                  <v-autocomplete
                    v-model="item.productId"
                    label="Продукт"
                    :items="ctx.src.products.items"
                    item-text="name"
                    item-value="id"
                    readonly
                  >

                  </v-autocomplete>
                </v-col>
                <!--          <v-col class="d-flex" cols="12" sm="6">-->
                <!--            <v-text-field-->
                <!--              v-model="vm.item.description"-->
                <!--              label="Описание"-->
                <!--            ></v-text-field>-->
                <!--          </v-col>-->
              </v-row>
            </v-container>
          </v-form>
        </div>
      </v-tab-item>
      <v-tab-item>
        <Programs :vm="vm"></Programs>
      </v-tab-item>
      <v-tab-item>
        <List :vm="this" :contractId="this.item.id" :main-view="false"></List>
      </v-tab-item>
    </v-tabs>
  </div>

</template>

<script>
import ctx from "@/ctx";
import Programs from '../programs/Master';
import List from "@/components/insured/List";
import fetch from "@/utils/fetch";
import SaveButton from "@/components/ButtonFacade";
import ContractHistory from "@/components/contracts/ContractHistory";
import {allowedToEditKiasContracts} from "@/utils/staticData/userPermissions"


export default {
  props: {
    item: Object,
    options: Object
  },
  data: () => ({
    ctx,
    requiredRules: [v => !!v || "Обязательное поле"],
    valid: false,
    vm: {},
    selected: [],

  }),
  watch: {
    item: function (val) {
      if (val) this.validate();
      this.init();
    }
  },
  computed: {
    currentUserCreatedContractNote() {
      return !this.item.id || ctx.src.contracts.createdByUser(this.item.id);
    },
    isNewEntry() {
      return !this.item.id
    },

    cantEdit() {


      return !this.$store.getters["isContractEditable"]
    }
  },
  methods: {
    validate() {
      if (!this.item || !this.$refs.form) return;
      this.$refs.form.validate();
    },
    init() {
      this.vm.item = this.item;
      this.item.organizationId = this.options.organizationId;
      this.selected = [...this.selected, this.item];
      this.$store.commit('calculate', {
        isNewEntry: !this.item.id,
        isKiasContract: this.item.isKiasContract,
        userCanEditKiasContracts: allowedToEditKiasContracts.some(id => id === localStorage.getItem('id'))
      })
    },
    close() {
      this.$emit('closeContractForm')
    },
    async save() {
      const res = await fetch.post('/api/insurance/contracts/save', this.item);
      if (!res) return;
      this.close();
      this.$emit('contractSaved')

    }
  },


  mounted() {
    this.init()


  },
  components: {

    ContractHistory,
    SaveButton,
    List,
    Programs
  }

};
</script>


