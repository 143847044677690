<template>
  <v-col
    class="d-flex"
    cols="12"
    sm="6"
    style="align-items: center; border-left: thin solid rgba(0,0,0,.2)"
  >
    <v-autocomplete
      v-model="selected"
      :items="items"
      :label="'Объединение программы'"
      item-text="number"
      item-value="id"
      required
    >
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title
              v-html="'Объединение ' + data.item.number"
            ></v-list-item-title>
            <v-list-item-subtitle>
              <v-row dense>
                <v-col>
                  <h6>Сумма</h6>
                  <h4>{{ data.item.amount }}</h4>
                </v-col>
              </v-row>
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
    <v-btn v-if="rightFilter('w')" icon @click="add">
      <v-icon>add</v-icon>
    </v-btn>
    <v-btn v-if="rightFilter('w')" icon :disabled="!selected" @click="edit">
      <v-icon>edit</v-icon>
    </v-btn>
    <v-btn
      v-if="rightFilter('d')"
      icon
      :disabled="!selected"
      @click="dialogDelete = true"
    >
      <v-icon>remove</v-icon>
    </v-btn>
    <v-spacer></v-spacer>
    <ProgramUnionHistory :item="this.vm.selected"></ProgramUnionHistory>
    <v-dialog v-model="dialog" max-width="500px" scrollable>
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container v-if="item">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="item.number"
                  label="Номер объединения"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="item.amount"
                  label="Страховая сумма"
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  v-model="item.quantity"
                  label="Количество"
                  type="number"
                >

                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">
            Отмена
          </v-btn>
          <SaveButton
          color="primary"
          :handler="save"
          content="OK"
          >

          </SaveButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
        >Вы точно хотите удалить объединение?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDelete">Отмена</v-btn>
          <v-btn color="primary" text @click="deleteConfirm">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
  import fetch from "../../utils/fetch";
  import js2vm from "../../utils/js2vm";
  import vm2js from "../../utils/vm2js";
  import rightFilter from "../../filters/right.filter";
  import ProgramUnionHistory from "./ProgramUnionHistory.vue";
  import SaveButton from "@/components/ButtonFacade";

  let Item = function(d) {
    let self = this;

    js2vm(self, d);
  };

  export default {
    props: {
      vm: Object
    },
    data: () => ({
      rightFilter,
      items: [],
      selected: undefined,
      item: undefined,
      dialog: false,
      dialogDelete: false
    }),
    watch: {
      "vm.selected": function(val) {
        this.selected = undefined;
        this.items = [];
        if (val) return this.fetchItems(val);
      },
      selected: function(val) {
        if (val) this.item = undefined;
        else this.item = val;
      }
    },
    methods: {
      async fetchItems(programId) {
        this.items = [];
        let res = await fetch.get(
          "/api/insurance/programs/get-unions-list?programId=" + programId
        );
        if (res)
          res.forEach(i => {
            this.items.push(new Item(i));
          });
        if (this.items.length > 0) this.selected = this.items[0].id;
      },

      add() {
        this.item = new Item({});
        this.dialog = true;
      },

      edit() {
        let d = this.items.find(i => {
          return i.id === this.selected;
        });
        this.item = new Item(vm2js(d));
        this.dialog = true;
      },

      async deleteConfirm() {
        const res = await fetch.post(
          "/api/insurance/programs/delete-union?id=" + this.selected
        );
        if (res) {
          let found = this.items.find(i => {
            return i.id === this.selected;
          });

          this.items.splice(found, 1);
          this.selected = undefined;
          if (this.items.length > 0) this.selected = this.items[0].id;
          this.closeDelete();
        }
      },

      close() {
        this.dialog = false;
      },

      closeDelete() {
        this.dialogDelete = false;
      },

      async save() {
        this.item.programId = this.vm.selected;
        this.item.limitType = "PC";
        const res = await fetch.post(
          "/api/insurance/programs/save-union",
          vm2js(this.item)
        );
        if (res) {
          if (this.item.id) {
            Object.assign(
              this.items.find(i => {
                return i.id === this.item.id;
              }),
              this.item
            );
          } else {
            this.item.id = res;
            this.items.unshift(new Item(vm2js(this.item)));
            this.selected = res;
          }
          this.vm.reloadDetail();
          this.close();
        }
      }
    },
    computed: {
      formTitle() {
        return this.editedIndex === -1 ? "Новая запись" : "Редактирование записи";
      }
    },
    created() {
      this.vm.unions = this;
    },
    components: {
      SaveButton,
      ProgramUnionHistory
    }
  };
</script>
