<template>
  <tr>
    <td>{{ index + 1 }}</td>
    <td>{{ item.state }}</td>
    <td>{{ item.date }}</td>
    <td>{{ item.userName }}</td>

    <td>
      {{ item.additional.number }}
    </td>

    <td>
      <v-autocomplete
        v-model="item.additional.organizationId"
        :items="ctx.src.org.items"
        item-value="id"
        item-text="fullName"
        readonly
      >
      </v-autocomplete>
    </td>

    <td>
      <v-autocomplete
        v-model="item.additional.underwriterId"
        :items="ctx.src.org.items"
        item-value="id"
        item-text="fullName"
        readonly
      >
      </v-autocomplete>
    </td>

    <td>
      <v-text-field v-model="item.additional.amount" readonly></v-text-field>
    </td>

    <td>
      <v-text-field
        v-model="item.additional.startDate"
        type="date"
        readonly
      ></v-text-field>
    </td>
    <td>
      <v-text-field
        v-model="item.additional.endDate"
        type="date"
        readonly
      ></v-text-field>
    </td>
  </tr>
</template>

<script>
import ctx from "@/ctx";

export default {
  props: {
    item: Object,
    index: Number
  },
  data: () => ({
    ctx
  }),
  mounted() {



  }
};
</script>

<style lang="scss" scoped>
td {
  padding: 0 2px !important;
}
</style>
